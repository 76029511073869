import Anunyam from "../assets/images/Anunyam.international.png";
import Expatswap from "../assets/images/Expatswap.png";
import Faicorn from "../assets/images/Faicorn.png";
import Lillajar from "../assets/images/Lillajar.png";
import Platute from "../assets/images/Platute.png";
import Vividadvisors from "../assets/images/Vividadvisors.png";
import Covcast from "../assets/images/covcast.png";
import Potrise from "../assets/images/potrise/Screenshot_20250212-214948.jpg";

const Portfolio = [
  {
    id: 1,
    title: "Vivid Advisors LLC",
    description:
      "Vivid Advisors LLC is a financial advisory firm that provides financial advisory services to its clients.",
    image: Vividadvisors,
    link: "https://vividadvisors.com/",
  },
  {
    id: 2,
    title: "Faicorn",
    description:
      "Faicorn is an online marketplace that allows users to buy and sell products and services.",
    image: Faicorn,
    link: "https://faicorn.com/",
  },
  {
    id: 3,
    title: "Expatswap",
    description:
      "Expatswap is a P2P marketplace allows you to swap currencies at your own rate. All you have to do is decide your rate and start swapping your fx with other users.",
    image: Expatswap,
    link: "https://expatswap.com/",
  },
  {
    id: 4,
    title: "Anunyam International",
    description:
      "Anunyam International is a platform for exporting non-traditional agricultural products like gari, palm oil, cassava flour (kokonte) etc to the international market.",
    image: Anunyam,
    link: "https://anunyam.international/",
  },
  {
    id: 5,
    title: "Lillajar",
    description:
      "Lillajar is a platform that allows users to rent out their properties to other users.",
    image: Lillajar,
    link: "https://therental-test.web.app/",
  },
  {
    id: 6,
    title: "Covcast",
    description:
      "Covcast is a church management platform that allows churches to manage their members, members' temperatures, groups, events, and donations.",
    image: Covcast,
    link: "https://covcast-5bc1f.web.app/",
  },
  {
    id: 7,
    title: "Platute",
    description:
      "Platute is a platform that allows students to find tutors and book lessons with them.",
    image: Platute,
    link: "https://platute.com/",
  },
  {
    id: 8,
    title: "Potrise",
    description:
      "Potrise is a platform that allows users to invest in real estates across Africa. Users can invest in real estates with as low as $5.",
    image: Potrise,
    onClick: ({ open, setOpen, setImages }) => {
      const images = require.context(
        "../assets/images/potrise",
        false,
        /\.(png|jpe?g|svg|mp4)$/
      );
      const importAll = (r) => r.keys().map(r);
      let imagesArray = importAll(images);
      imagesArray = imagesArray.map((image) => {
        let imageObj = {
          src: image,
          type: "image",
          alt: "potrise",
        };

         if (image.includes("mp4")) {
          imageObj.type = "video";
          imageObj.width = 1280;
          imageObj.height = 720;
          imageObj.sources = [
            {
              src: image,
              type: "video/mp4",
            },
           ];
           delete imageObj.src;
        }

        return imageObj;
      });
      setImages(imagesArray);
      setOpen(!open);
    },
  },
];

export default Portfolio;
