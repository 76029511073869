import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";
import SearchData from "../../../data/searchData";
import "./Navbar.css";

export const Navbar = () => {
  const navigations = [
    { name: "Home", path: "#home" },
    { name: "About", path: "#about" },
    { name: "Portfolio", path: "#portfolio" },
    { name: "Experience", path: "#experience" },
    { name: "Contact", path: "#contact" },
  ];

  const location = useLocation();

  const [showInput, setShowInput] = useState(false);
  const [navbarScrollActive, setNavbarScrollActive] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setNavbarScrollActive(true);
      } else {
        setNavbarScrollActive(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    setSearchData(SearchData);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${navbarScrollActive ? "active" : ""}`}>
      <div className="navbar__logo">
        <a href="/">
          <img src={Logo} alt="Logo" />
        </a>
      </div>
      <ul className="navbar__list">
        <li className="navbar__list-item">
          <a
            href="#!"
            className="navbar__list-item-close"
            onClick={(e) => {
              e.preventDefault();
              document
                .querySelector(".navbar__list")
                .classList.remove("active");
            }}
          >
            <i className="bi bi-x-lg"></i>
          </a>
        </li>
        {navigations.map((nav, index) => (
          <li key={index} className="navbar__list-item">
            <a
              href={nav.path}
              className={`fs-18 ${
                location.hash === nav.path ||
                (!location.hash && nav.path === "#home")
                  ? "active"
                  : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                document
                  .querySelector(".navbar__list")
                  .classList.remove("active");
                window.location.hash = nav.path;
              }}
            >
              {nav.name}
            </a>
          </li>
        ))}
      </ul>
      <div className="navbar__search">
        <div className={`navbar__search-input ${showInput ? "active" : ""}`}>
          <input
            type="text"
            name="search"
            autoComplete="off"
            id="search"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              setSearchData(
                SearchData.filter(
                  (item) =>
                    item.title
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    item.description
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                )
              );
            }}
            placeholder="Search"
          />
          {searchData.length > 0 && (
            <div
              className={`navbar__search-input-result ${
                searchValue.length > 0 ? "active" : ""
              }`}
              id="searchResult"
            >
              {searchData.map((item, index) => (
                <a
                  key={index}
                  href={item.link}
                  className="navbar__search-input-result-item"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.hash = item.link;
                    document
                      .getElementById("searchResult")
                      .classList.remove("active");
                    setSearchValue("");
                    setSearchData(SearchData);
                    setShowInput(false);
                  }}
                >
                  <div className="title">{item.title}</div>
                  <div className="description">{item.description}</div>
                  <div className="category">{item.category}</div>
                </a>
              ))}
            </div>
          )}
        </div>
        <i
          className="bi bi-search"
          onClick={() => setShowInput(!showInput)}
        ></i>
      </div>
      <button
        className="navbar__btn"
        onClick={() => {
          document.querySelector(".navbar__list").classList.toggle("active");
        }}
      >
        <i className="bi bi-list"></i>
      </button>
    </nav>
  );
};
